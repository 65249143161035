<template>
  <div class="home">
    <span @click="changeMode"><van-icon name="exchange" />切换扣分模式</span>
    <div class="garde">
      <span class="num">{{sumGrade}}分 </span>
      <van-button type="danger" size="small" class="reset" @click="onReset">清空计算</van-button>
    </div>
    <div class="container">
      <div class="grade-list">
        <div v-for="(item, index) in logs" class="item" >
          <span>{{item}}</span>
          <van-button type="danger" size="mini" @click="onDelete(index)">删除</van-button>
        </div>
      </div>
    </div>

    <van-row class="op-container" gutter="10">
      <van-col span="8">
        <van-button type="primary" block @click="calc(0.5)" >+0.5分</van-button>
      </van-col>
      <van-col span="8">
        <van-button type="primary" block @click="calc(1)" >+1分</van-button>
      </van-col>
      <van-col span="8">
        <van-button type="primary" block @click="calc(1.5)" >+1.5分</van-button>
      </van-col>
      <van-col span="8">
        <van-button type="primary" block @click="calc(2)" >+2分</van-button>
      </van-col>
      <van-col span="8">
        <van-button type="primary" block @click="calc(3)" >+3分</van-button>
      </van-col>
      <van-col span="8">
        <van-button type="primary" block @click="calc(5)" >+5分</van-button>
      </van-col>
      <van-col span="8">
        <van-button type="primary" block @click="calc(10)" >+10分</van-button>
      </van-col>
      <van-col span="8">
        <van-button type="primary" block @click="calc(20)" >+20分</van-button>
      </van-col>
      <van-col span="8">
        <van-button type="primary" block @click="onCus" >自定义</van-button>
      </van-col>
    </van-row>
    <van-dialog v-model="show" title="自定义分数" @confirm="onCusConfirm" show-cancel-button>
      <van-radio-group direction="horizontal" style="margin: 20px" v-model="cusType">
        <van-radio name="1">扣分</van-radio>
        <van-radio name="2">加分</van-radio>
      </van-radio-group>
      <van-field v-model="cusValue" type='number' :label="cusType==='1'?'扣分':'加分'" placeholder="请输入数字" />
    </van-dialog>

    <van-dialog v-model="scoreShow" title="自定义总分" @confirm="onCusScoreConfirm" show-cancel-button>
      <van-field v-model="cusScoreValue" type='number' label="总分" placeholder="请输入数字" />
    </van-dialog>
  </div>
</template>

<script>

import {Dialog} from "vant";
import {mapState} from 'vuex'

export default {
  name: 'HomeView',
  data(){
    return {
      grade: 0,
      logs: [],
      show: false,
      cusValue: '',
      cusType: '2',
      scoreShow: false,
      cusScoreValue: '',
    }
  },
  computed: {
    ...mapState({
      sumScore: state => state.sumScore
    }),
    sumGrade: function () {
      let sum = this.grade
      this.logs.forEach(item => {
        sum += item
      })
      return sum
    }
  },
  methods: {
    calc: function (grade) {
      this.logs.unshift(grade)
    },
    onReset: function () {
      this.logs = []

    },
    onDelete: function (index) {
      this.logs.splice(index, 1)
    },
    onCus: function (){
      this.show = true
      this.cusValue = ''
      this.cusType = '2'
    },
    onCusConfirm: function () {
      if (this.cusType === '1') {
        this.logs.unshift(-(Number(this.cusValue)))
      } else {
        this.logs.unshift((Number(this.cusValue)))
      }
    },
    onCusScoreConfirm: function () {
      this.$store.dispatch('setSumScore', Number(this.cusScoreValue))
    },
    changeMode: function () {

      if (this.logs.length > 0){
        Dialog.confirm({
          title: '温馨提示',
          message: '当前有计算数据，切换模式计算数据将清空，确定切换吗？',
        })
            .then(() => {
              this.$router.push({name: 'home'})
            })
            .catch(() => {
              // on cancel
            });
        return
      }

      this.$router.push({name: 'home'})
    }
  }
}
</script>

<style lang="scss">
.home{
  padding: 10px;
  .op-container{
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 10px;
  }
  .van-col{
    margin-bottom: 10px;
  }
  .garde{
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .num{
      font-size: 32px;
      text-align: center;
      color: #07c160;
      font-weight: 700;
      min-width: 140px;
      text-align: center;
    }
    .reset{
      margin-left: 10px;
    }
  }
  .container{
    height: calc(100vh - 128px - 86px - 50px);
    overflow-y: scroll;
  }
  .grade-list{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .item{
      width: 24%;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding-right: 2%;
      padding-left: 3%;
    }
  }
}
</style>
