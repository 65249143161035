import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sumScore: 100
  },
  getters: {
  },
  mutations: {
    SET_SUM_SCORE: (state, score) => {
      state.sumScore = score
    }
  },
  actions: {
    setSumScore: ({ commit }, score) => {
      commit('SET_SUM_SCORE', score)
    }
  },
  modules: {
  }
})
